<template>
  <div class="menu">
    <div class="logo left">
      <a href="/">Caroline Léger</a>
      <br />
      <span>Autrice de l'imaginaire</span>
    </div>

    <div class="right">
      <ul>
        <li><a href="#bio">Biographie</a></li>
        <li><a href="#histoires">Mes histoires</a></li>
        <li><a href="#couvertures">Couvertures</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>
    </div>
  </div>
</template>
