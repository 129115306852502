<template>
  <section class="contact" id="contact">
    <div class="social text-center">
      <p>Contactez-moi sur:</p>
      <a href="https://www.instagram.com/1livre1image/" target="_blank"
        ><img src="../assets/images/instagram.png" alt="instagram"
      /></a>
      <a
        href="https://www.wattpad.com/myworks/92312892-l%27%C3%A9cho-des-r%C3%AAves"
        target="_blank"
        ><img src="../assets/images/wattpad.png" alt="wattpad"
      /></a>
      <a
        href="https://www.goodreads.com/user/show/15564333-caroline-leger"
        target="_blank"
        ><img src="../assets/images/goodreads.png" alt="goodreads"
      /></a>
      <a href="mailto:caroline_leger64@hotmail.fr"
        ><img src="../assets/images/mail.png" alt="goodreads"
      /></a>
    </div>

    <div class="copyright">Copyright © 2021 Caroline Leger</div>
  </section>
</template>
