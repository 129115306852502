<template>
  <section class="bio" id="bio">
    <div class="main-content">
      <div class="left w-50">
        <div class="subtitle">Biographie</div>
        <div class="bio__content">
          Développeuse web, globe-trotteuse et rêveuse invétérée, j'ai grandi à
          Biarritz au cœur des années 90. <br /><br />
          En 2011, j'ai quitté mon Pays basque natal pour m’envoler vers des
          contrées lointaines. Après l’Angleterre et l’Inde, j'ai finalement
          arrêté mon chemin en Australie, où j'habite depuis cinq ans.<br /><br />
          Ma soif de découverte s’accompagne d’une passion pour la SFFF : je ne
          me lasse pas de plonger dans de nouvelles aventures qu’elles soient
          sous forme de livres, mangas, films, séries ou jeux vidéo.<br /><br />
          Je passe la plupart de mon temps devant un ordinateur ; quand je
          n’écris pas des lignes de code, j'aime créer des histoires imprégnées
          de mes voyages physiques ou imaginaires.
        </div>
      </div>
      <div class="right w-50">
        <img
          src="../../assets/images/portrait.jpg"
          alt="portrait"
          class="portrait wow slideInDown"
          data-wow-delay="1s"
          data-wow-offset="100"
        />
      </div>
    </div>
    <img
      src="../../assets/images/bottom-portfolio.jpg"
      alt=""
      class="bottom-wave"
    />
  </section>
</template>
